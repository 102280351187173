import React from 'react'
import Layout from "../../Components/reusable/Layout"
import Banner from "../../Components/reusable/Banner"
import AboutImg from "../../Images/About/aboutus.jpg"

function About() {
    return (
        <Layout>
          <Banner title="About Kundan Lal and Sons"/> 

    <div className="section">
		<div className="content-wrap">
			<div className="container">

				<div className="row">

					<div className="col-sm-6 col-md-6 pright">
						<div className="img-subsco">
							{/* <div className="icon-play-yt">
								<a href="http://www.youtube.com/watch?v=0O2aH4XLbto" className="popup-youtube"><i className="fas fa-play-circle"></i></a>
							</div> */}
							<img src={AboutImg} alt="" className="img-fluid" />
						</div>
					</div>
					<div className="col-sm-6 col-md-6">
						<h4 className="section-heading text-black no-after mb-3">
							About <span className="text-primary">Us</span>
						</h4>
						<h3 className="text-black">
                        Leading manufacturer and supplier of Sprockets, Gears and Spline Shafts
						</h3>
						<p> 
							<ul style={{marginLeft: "0px", paddingLeft: "23px"}}>
								<li>We offer these products in a variety of sizes, dimensions and designs with customization done to cater to the specific demands of the clients.</li>
								<li>Strategically located in the industrial hub of Ludhiana, Punjab, we are near all the resources needed to function effectively and provide high quality Sprockets, Gears and Spline Shafts for Automotive, Industrial and Agricultural sector.</li>
								<li>Our position in the market is strengthened by the presence of an experienced team, robust infrastructure, association with reliable vendors and an extensive network that has helped us in the development of a loyal client base.</li>
							</ul> 
</p>
						{/* <a href="/" className="btn btn-secondary">Our Projects</a> */}
						<div className="spacer-30"></div>
					</div>
					
				</div>

				
			</div>
		</div>
	</div>
 
        </Layout>
    )
}

export default About
